<template>
<div class="animated fadeIn">

</div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'SuperadminDashboard',
}
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
    width: 100% !important;
}
</style>
